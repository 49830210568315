* {
    margin: 0;
    padding: 0;
}

body {
    max-width: 1400px;
    margin: 0 auto;
    font-family: "Helvetica", sans-serif;
    font-weight: 300;
    color: #222121;
    background-color: #f6f4f4;
    line-height: 1.3;
}

table {
    margin-top: 14px;
}

td {
    padding-bottom: 16px;
    vertical-align: top;
}

tr td:first-child {
    width: 30%;
    padding-right: 32px;
}

#wrapper {
    padding: 0 2rem;
}

// TYPOGRAPHY
#title {
    font-size: 2.7rem;
    margin-top: 5rem;
    margin-bottom: 2rem;
    border-bottom: 1px solid rgb(204, 204, 204);
}

#new-title {
    font-size: 2rem;
    font-weight: 600;
    text-transform: uppercase;
}

h1,
h2,
h3 {
    font-weight: 100;
}

h1 {
    font-size: 1.4rem;
    font-weight: 600;
    margin: 0;
    padding: 0;
}

h2,
h3 {
    margin-top: 2rem;
}

p {
    line-height: 1.5;
}

span {
    font-style: italic;
}

// LINKS
a {
    text-decoration: none;
    color: #222121;
}

a:visited {
    color: #222121;
}

a:hover {
    text-decoration: none;
    color: black;
}

.current {
    text-transform: uppercase;
}

.no-hover {
    color: black !important;
}

// FLEXBOX SETTINGS
.flex-container {
    display: flex;
    justify-content: space-between;
}

.vertical {
    flex-direction: column;
}

.align-center {
    align-items: center;
}

// header

.header {
    margin-top: 4rem;
    margin-bottom: 4rem;
}

// NAVIGATION AND MENUS

.main-nav {
    font-weight: 600;
}

.nav-item {
    margin-left: 4rem;
}

ul {
    list-style: none;
    text-transform: lowercase;
    color: #222121;
    font-size: 1.2rem;
}

ul > li > ul {
    margin-left: 1.5rem;
    text-transform: lowercase;
}

.work-link {
    font-size: 1rem;
    font-weight: 600;
    line-height: 2;
}

#toggle-work-menu {
    pointer-events: none;
    font-style: normal;
}

#work-menu-mobile {
    display: none;
}

.grid-container {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
}
.grid-item {
    aspect-ratio: 4 / 3;
    overflow: hidden;
    padding: 4px;
}

.grid-item img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
}

// Content
.contact {
    font-size: 1.2rem;
}

i {
    margin-right: 0.5rem;
}

img {
    max-width: 100%;
}

.material {
    margin-top: 0;
    margin-bottom: 2rem;
}

// Footer
footer {
    margin-top: 2rem;
    border-top: 1px solid rgb(204, 204, 204);
}

footer p {
    text-align: right;
}

.smaller-font {
    font-size: 12px;
}

ul.dashed {
    margin-top: 14px;
    list-style-type: " -   ";
    text-transform: none;
    color: #222121;
}

ul.dashed > li {
    margin-top: 6px;
}

.margin-bottom {
    margin-bottom: 72px;
}

@media screen and (max-width: 750px) {
    .flex-container {
        align-items: center;
        justify-content: space-between;
        flex-direction: column;
    }

    .vertical {
        flex-direction: row;
    }

    #new-title {
        font-size: 1.8rem;
        text-align: left;
        width: 100%;
    }

    .main-nav {
        width: 100%;
    }

    .main-nav ul {
        flex-direction: row;
        justify-content: left;
        width: 100%;
    }

    .nav-item {
        margin: 0;
        margin-right: 1em;
    }

    .grid-container {
        grid-template-columns: repeat(2, 1fr);
    }
}
